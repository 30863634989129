<template>
  <div class="wrapper">
    <header class="wrapper-content">
      <h1>Roda da vida online</h1>
      <h2>O que é a roda da vida?</h2>
      <p>A roda da vida é uma ferramenta visual que auxilia no processo de análise e reflexão sobre a vida, para cada área da vida você deve se questionar qual o seu nível de satisfação, com o objetivo de visualizar as áreas da sua vida que precisam de atenção. O exercício consiste em mapear as áreas da sua vida em um círculo, por isso o nome roda da vida.</p>
    </header>
    <div class="wrapper-content">
      <div class="highlight">
        <button class="dropdown" @click="handleDropdown">
          <svg class="icon icon-arrow" viewBox="0 0 32 32">
            <path d="M19.414 27.414l10-10c0.781-0.781 0.781-2.047 0-2.828l-10-10c-0.781-0.781-2.047-0.781-2.828 0s-0.781 2.047 0 2.828l6.586 6.586h-19.172c-1.105 0-2 0.895-2 2s0.895 2 2 2h19.172l-6.586 6.586c-0.39 0.39-0.586 0.902-0.586 1.414s0.195 1.024 0.586 1.414c0.781 0.781 2.047 0.781 2.828 0z"></path>
          </svg>
          <span>As categorias da roda da vida</span>
        </button>
        <div class="hidden">  
          <p>A roda da vida geralmente se divide em 12 categorias. O nome de cada categoria é uma decisão sua, aqui temos alguns exemplos das categorias comumente utilizadas:</p>
          <ul>
            <li>Crescimento pessoal ou Desenvolvimento pessoal</li>
            <li>Carreira ou Profissional</li>
            <li>Finanças</li>
            <li>Lazer e/ou Diversão</li>
            <li>Amor e/ou Relacionamento</li>
            <li>Casa</li>
            <li>Saúde</li>
            <li>Família e amigos</li>
            <li>Vida social</li>
            <li>Espiritualidade</li>
          </ul>
        </div>
      </div>
      <div class="highlight">
        <button class="dropdown" @click="handleDropdown">
          <svg class="icon icon-arrow" viewBox="0 0 32 32">
            <path d="M19.414 27.414l10-10c0.781-0.781 0.781-2.047 0-2.828l-10-10c-0.781-0.781-2.047-0.781-2.828 0s-0.781 2.047 0 2.828l6.586 6.586h-19.172c-1.105 0-2 0.895-2 2s0.895 2 2 2h19.172l-6.586 6.586c-0.39 0.39-0.586 0.902-0.586 1.414s0.195 1.024 0.586 1.414c0.781 0.781 2.047 0.781 2.828 0z"></path>
          </svg>
          <span>Como usar a roda da vida</span>
        </button>
        <div class="hidden">  
          <p>Você pode criar a sua roda da vida seguindo estes passos:</p>
          <ul>
            <li>
              <p>Comece dedicando algum tempo para pensar em quais são as áreas mais importantes na sua vida (as categorias acima podem lhe dar algumas idéias). Para isso você pode se perguntar:</p>
              <ul>
                <li>Quais são as áreas mais importantes da minha vida? <span>Exemplo: educação, carreira, família, independência financeira, expressão artística, fitness</span></li>
                <li>Quais papéis você desempenha? <span>Exemplo: amiga(o), líder da comunidade (ou de um time), esportista, membro de uma equipe, colega, gerente, mãe/pai, esposa/esposo</span></li>
              </ul>
              <p>Você pode utilizar uma combinação das perguntas acima, a intenção é refletir sobre quais são as prioridades na sua vida.</p>
            </li>
            <li>
              <p>Agora você pode editar os campos abaixo adicionando as categorias que você escolhe como prioridades.</p>
            </li>
            <li>
              <p>É normal que de tempos em tempos tenhamos que dedicar mais atenção a algumas áreas do que a outras. O objetivo da roda da vida é tentar encontrar equilibrio em cada área.</p>
              <p>Ao lado de cada categoria você pode escolher um número de 1 a 10 (se preferir, você pode também pode alterar a cor de cada categoria). Para cada categoria pense em o quanto você tem se dedicado a categoria em questão, em uma escala de 0 a 10, onde 0 é o menor nível de dedicação e 10 é o maior.</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="wrapper-tool">
      <div class="flex">
        <form>
          <fieldset v-for="(item, index) in data" :key="item.color">
            <label :for="'area-' + index">área da vida {{index}}</label>
            <input
              v-model="item.name"
              type="text"
              maxlength="30"
              minlength="1"
              @input="updateChart"
              required
              autofocus
              :id="'area-' + index"
            />
            <label :for="'nota-' + index">nota para a área da vida {{index}}</label>
            <input
              v-model="item.level"
              type="number"
              min="1"
              max="10"
              @input="updateChart"
              required
              :id="'nota-' + index"
            />
            <label :for="'cor-' + index">cor para a área da vida {{index}}</label>
            <input
              v-model="item.color"
              type="color"
              name="color"
              @input="updateChart"
              :id="'cor-' + index"
            />
          </fieldset>
        </form>
        <div id="chart"></div>
      </div>
      <button class="button" @click="saveChart">
        <span>Salvar</span>
        <svg class="icon" viewBox="0 0 32 32">
          <path d="M18 8l-4-4h-14v26h32v-22h-14zM16 27l-7-7h5v-8h4v8h5l-7 7z"></path>
        </svg>
      </button>
    </div>
    <div class="wrapper-content">
      <h3>Parabéns, a sua roda da vida está pronta!<br>E agora?</h3>
      <p>Com a roda da vida pronta, você pode comparar cada área com a pontuação que você considera ideal em cada categoria. Use essa ferramenta para identificar as áreas em desequilibrio e decidir quais mudanças e ações são necessárias para encontrar o equilibrio.</p>
      <p>Você pode salvar a roda da vida e, de tempos em tempos, refazer esse exercício e comparar os resultados.</p>
      <div class="disclaimer">
        <p>Essa ferramente foi criada inicialmente para meu uso pessoal, mas como não encontrei outras fontes online decici publicá-la e espero que seja útil pra você também.</p>
        <p>O conceito da roda da vida foi inicialmente criado por Paul J. Meyer. Para entender mais sobre a roda da vida recomendo este post do site <a href="https://vidaorganizada.com/2019/12/11/analise-das-areas-da-minha-vida-e-escolha-do-meu-foco-para-2020/" target="_blank">Vida organizada</a></p>
        <!-- <p>Essa ferramente foi criada inicialmente para meu uso pessoal. Espero que seja útil pra você. ♡ </p> -->
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { saveAs } from 'file-saver'
import radialBarChart from './assets/radialBarChart.js'

export default {
  name: 'App',
  data() {
    return {
      data: [
        {
          name: 'Espiritualidade',
          placeholder: 'Espiritualidade',
          level: 1,
          color: '#9ed110',
        },
        {
          name: 'Saúde',
          placeholder: 'Saúde',
          level: 2,
          color: '#50b517',
        },
        {
          name: 'Desenvolvimento intelectual',
          placeholder: 'Desenvolvimento intelectual',
          level: 3,
          color: '#179067',
        },
        {
          name: 'Equilíbrio emocional',
          placeholder: 'Equilíbrio emocional',
          level: 4,
          color: '#476eaf',
        },
        {
          name: 'Propósito',
          placeholder: 'Propósito',
          level: 5,
          color: '#9f49ac',
        },
        {
          name: 'Finanças',
          placeholder: 'Finanças',
          level: 6,
          color: '#cc42a2',
        },
        {
          name: 'Familia',
          placeholder: 'Familia',
          level: 10,
          color: '#ff3ba7',
        },
        {
          name: 'Amor / Relacionamento',
          placeholder: 'Amor / Relacionamento',
          level: 10,
          color: '#ff5800',
        },
        {
          name: 'Social',
          placeholder: 'Social',
          level: 10,
          color: '#ff8100',
        },
        {
          name: 'Lazer',
          placeholder: 'Lazer',
          level: 10,
          color: '#feac00',
        },
        {
          name: 'Plenitude',
          placeholder: 'Plenitude',
          level: 10,
          color: '#ffcc00',
        },
        {
          name: 'Contribuição com o mundo',
          placeholder: 'Contribuição com o mundo',
          level: 10,
          color: '#ede604',
        },
      ],
    }
  },
  mounted() {
    const chart = radialBarChart()
      .barHeight(350)
      .reverseLayerOrder(true)
      .capitalizeLabels(true)
      .barColors(this.data.map((el) => el.color))
      .domain([0, 10])
      .tickValues([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
      .tickCircleValues([1, 2, 3, 4, 5, 6, 7, 8, 9])

    d3.select('#chart')
      .datum(() => {
        const testing = [{ data: {} }]
        this.data.map((el) => (testing[0].data[el.name] = el.level))
        return testing
      })
      .call(chart)
  },
  methods: {
    updateChart(event) {
      event.target.classList.remove('invalid');
      if (!event.target.value) {
        event.target.classList.add('invalid');
        event.target.value = event.target.placeholder
        return;
      }
      if (event.target.type === 'number' && (parseInt(event.target.value) > 10 || parseInt(event.target.value) < 1)) {
        event.target.classList.add('invalid');
      }
      const chart = radialBarChart()
        .barHeight(350)
        .reverseLayerOrder(true)
        .capitalizeLabels(true)
        .barColors(this.data.map((el) => el.color))
        .domain([0, 10])
        .tickValues([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
        .tickCircleValues([1, 2, 3, 4, 5, 6, 7, 8, 9])

      d3.select('#chart')
        .datum(() => {
          const testing = [{ data: {} }]
          this.data.map((el) => {
            let name = el.name;
            if (!name) {
              name = el.placeholder
            }
            testing[0].data[name] = parseInt(el.level) > 10 ? 10 : parseInt(el.level) < 1 ? 1 : parseInt(el.level);
          });
          return testing
        })
        .call(chart)
    },
    chart() {
      return radialBarChart()
        .barHeight(350)
        .reverseLayerOrder(true)
        .capitalizeLabels(true)
        .barColors(this.data.map((el) => el.color))
        .domain([0, 10])
        .tickValues([1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
        .tickCircleValues([1, 2, 3, 4, 5, 6, 7, 8, 9])
    },
    saveChart() {
      // https://github.com/eligrey/FileSaver.js
      // check support
      // try {
      //     var isFileSaverSupported = !!new Blob;
      // } catch (e) {}
      const canvasEl = document.createElement('canvas')
      canvasEl.setAttribute('id', 'canvas')
      canvasEl.setAttribute('width', '800px')
      canvasEl.setAttribute('height', '800px')
      canvasEl.setAttribute('style', 'display: none')
      document.body.appendChild(canvasEl)
      const svgString = new XMLSerializer().serializeToString(
        document.querySelector('#chart svg')
      )
      const canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      const DOMURL = self.URL || self.webkitURL || self
      const img = new Image()
      const svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
      const url = DOMURL.createObjectURL(svg)
      img.onload = function () {
        ctx.drawImage(img, 0, 0)
        canvas.toBlob((blob) => saveAs(blob, 'rodadavida.png'))
        document.querySelector('#canvas').remove()
      }
      img.src = url
    },
    handleDropdown(e) {
      const el = e.target.classList.contains('dropdown') ? e.target : e.target.closest('.dropdown'); 
      el.parentElement.classList.toggle('is-active')
      const hiddenContent = el.nextSibling;
      hiddenContent.classList.toggle('hidden')
    }
  },
}
</script>

<style>
body {
  min-width: 800px;
  max-width: 1280px;
  margin: 0 auto;
  background-color:#fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2em;
  line-height: 1.5;
}
html, body {
  color: #59637c;
  font-family: "Open Sans", sans-serif;
}
.wrapper-content {
  width: 90%;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
/* p {
  max-width: 1200px;
  margin: 1em 2em;
} */
h1, h2, h3, .dropdown {
  color: #17253c;
  font-family: "Raleway", sans-serif;
}
h1 {
  text-align: center;
  color: #6159e6;
  background-color: #f1f0ff;
  padding: 1em 0;
}
/* .intro {
  margin: 0 1em;
} */
form {
  margin: 0 auto;
}
#chart {
  text-align: center;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#chart .layer-0 path, #chart .layer-1 path {
  fill: white;
}
input {
  margin-right: .5em;
  /* line-height: 2; */
  padding-left: .5em;
}

input[type="text"] {
  color: #444;
  min-width: 200px;
  line-height: 2;
}

input[type="number"] {
  max-width: 50px;
}

input.invalid {
  border: 1px solid red;
}

input:focus {
  color: #000;
  outline: none;
  background: #fffcdf;
  
}

input[type="color"] {
  border: none;
  outline: none;
  background: none;
  margin-right: 0;
  padding: 0;
  cursor: pointer;
}

input[type="color"]:focus {
  border: 2px solid #000;
}

label:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

form {
  margin-bottom: 2em;
  text-align: center;
}

fieldset {
  padding: 0;
  border: none;
  text-align: left;
  /* display: inline-block; */
  display: inline-flex;
  margin: .2em 0;
  align-items: center;
}

.button {
  margin: 2em auto;
  padding: .8em 2em;
  color: #fff;
  background: #18b979;
  text-decoration: none;
  /* padding: .5em; */
  cursor: pointer;
  border: none;
  font-size: 1em;
  display: flex;
  border-radius: 25px;
  box-shadow: 0px 0px 3px 0 #a6dfc6;
  transition: ease-in background 300ms;
  text-transform: uppercase;
  font-weight: bold;
}

/* .button:hover {
  background: rgba(0,0,255,.06);
} */

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: #fff;
  fill: #fff;
  margin-left: .5em;
}

.dropdown {
  font-weight: normal;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  font-size: 1em;
  /* margin-bottom: 1em; */
  cursor: pointer;
  padding: 1em;
  width: 100%;
}

.highlight div {
  padding: 0 1em 1em;
}

.highlight.is-active {
  background-color: #18b97914;
}

.dropdown .icon {
  fill: #18b979;
  margin-left: 0;
  margin-right: .5em;
}

.hidden {
  display: none;
}

.disclaimer {
  margin: 5em 0 1em;
  /* padding: 1em; */
  font-size: .75em;
  border-top: 1px solid;
}

.wrapper-tool {
  background-color: #18b97914;
  margin-top: 2em;
  padding: 1em;
}

@media (min-width: 1280px) {
  .wrapper-tool .flex {
    display: flex;
    align-items: center;
  }
  .wrapper-tool form {
    width: 35%;
  }
  .wrapper-tool #chart {
    width: 65%;
  }
  form {
    margin: 0 2em;
    text-align: left;
  }
  .button {
    margin: 2em auto;
  }
}
</style>
