import * as d3 from 'd3'

export default function radialBarChart() {
  // Configurable variables
  let margin = { top: 20, right: 20, bottom: 20, left: 20 }
  let barHeight = 100
  let reverseLayerOrder = false
  // eslint-disable-next-line no-undef-init
  let barColors = undefined
  let capitalizeLabels = false
  let domain = [0, 100]
  // eslint-disable-next-line no-undef-init
  let tickValues = undefined
  let colorLabels = false
  let tickCircleValues = []
  let transitionDuration = 1000

  // Scales & other useful things
  let numBars = null
  let barScale = null
  let keys = null
  let labelRadius = 0

  // console.log('init')
  function init(d) {
    barScale = d3.scale.linear().domain(domain).range([0, barHeight])
    keys = d3.map(d[0].data).keys()
    numBars = keys.length

    // Radius of the key labels
    labelRadius = barHeight * 1.025
  }

  function svgRotate(a) {
    return 'rotate(' + +a + ')'
  }

  function svgTranslate(x, y) {
    return 'translate(' + +x + ',' + +y + ')'
  }

  function initChart(container) {
    const g = d3
      .select(container)
      .append('svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('xml:space', 'preserve')
      .attr('width', 2 * barHeight + margin.left + margin.right + 'px')
      .attr('height', 2 * barHeight + margin.top + margin.bottom + 'px')
      .style('font-family', '"Helvetica Neue", Helvetica, sans-serif')
      .style('font-size', '11px')
      .append('g')
      .classed('radial-barchart', true)
      .attr(
        'transform',
        svgTranslate(margin.left + barHeight, margin.top + barHeight)
      )

    // Concentric circles at specified tick values
    g.append('g')
      .classed('tick-circles', true)
      .selectAll('circle')
      .data(tickCircleValues)
      .enter()
      .append('circle')
      .attr('r', function (d) {
        return barScale(d)
      })
      .style('fill', 'none')
      .attr('stroke', '#ddd')
      .attr('stroke-dasharray', '2, 2')
  }

  function renderOverlays(container, update) {
    if (!update) {
      const g = d3.select(container).select('svg g.radial-barchart')

      // Spokes
      g.append('g')
        .classed('spokes', true)
        .selectAll('line')
        .data(keys)
        .enter()
        .append('line')
        .attr('stroke', '#666')
        .attr('stroke-width', '0.2')
        .attr('y2', -barHeight)
        .attr('transform', function (d, i) {
          return svgRotate((i * 360) / numBars)
        })

      // Axis
      const axisScale = d3.scale.linear().domain(domain).range([0, -barHeight])
      const axis = d3.svg.axis().scale(axisScale).orient('right')
      if (tickValues) axis.tickValues(tickValues)
      g.append('g').classed('axis', true).call(axis)

      g.select('path.domain').style('fill', 'none')

      // Outer circle
      g.append('circle')
        .attr('r', barHeight)
        .attr('stroke', '#aaa')
        .attr('stroke-dasharray', '4, 4')
        .classed('outer', true)
        .style('fill', 'none')

      const labels = g.append('g').classed('labels', true)
      labels
        .append('def')
        .append('path')
        .attr('id', 'label-path')
        .attr(
          'd',
          'm0 ' +
            -labelRadius +
            ' a' +
            labelRadius +
            ' ' +
            labelRadius +
            ' 0 1,1 -0.01 0'
        )

      labels
        .selectAll('text')
        .data(keys)
        .enter()
        .append('text')
        .style('text-anchor', 'middle')
        .style('fill', '#333')
        .append('textPath')
        .attr('xlink:href', '#label-path')
        .attr('startOffset', function (d, i) {
          return (i * 100) / numBars + 50 / numBars + '%'
        })
        .text(function (d) {
          return capitalizeLabels
            ? d.slice(0, 30).toUpperCase()
            : d.slice(0, 30)
        })
    }

    // Labels

    if (update) {
      const labels = d3.select(container).select('svg g.labels')
      labels.selectAll('text').remove()

      labels
        .append('def')
        .append('path')
        .attr('id', 'label-path')
        .attr(
          'd',
          'm0 ' +
            -labelRadius +
            ' a' +
            labelRadius +
            ' ' +
            labelRadius +
            ' 0 1,1 -0.01 0'
        )

      labels
        .selectAll('text')
        .data(keys)
        .enter()
        .append('text')
        .style('text-anchor', 'middle')
        .style('fill', function (d, i) {
          return colorLabels ? barColors[i % barColors.length] : null
        })
        .append('textPath')
        .attr('xlink:href', '#label-path')
        .attr('startOffset', function (d, i) {
          return (i * 100) / numBars + 50 / numBars + '%'
        })
        .text(function (d) {
          return capitalizeLabels
            ? d.slice(0, 30).toUpperCase()
            : d.slice(0, 30)
        })
    }
  }

  function chart(selection) {
    selection.each(function (d) {
      init(d)

      if (reverseLayerOrder) d.reverse()

      let g = d3.select(this).select('svg g.radial-barchart')

      // check whether chart has already been created
      const update = g[0][0] !== null // true if data is being updated

      if (!update) initChart(this)

      g = d3.select(this).select('svg g.radial-barchart')

      // Layer enter/exit/update
      const layers = g.selectAll('g.layer').data(d)

      layers
        .enter()
        .append('g')
        .attr('class', function (d, i) {
          return 'layer-' + i
        })
        .classed('layer', true)

      layers.exit().remove()

      // Segment enter/exit/update
      const segments = layers.selectAll('path').data(function (d) {
        const m = d3.map(d.data)
        return m.values()
      })

      segments.enter().append('path')

      segments.exit().remove()

      segments
        .transition()
        .duration(transitionDuration)
        .style('fill', function (d, i) {
          if (!barColors) return
          return barColors[i % barColors.length]
        })
        .attr(
          'd',
          d3.svg
            .arc()
            .innerRadius(0)
            .outerRadius(function (d) {
              return barScale(d)
            })
            .startAngle(function (d, i) {
              return (i * 2 * Math.PI) / numBars
            })
            .endAngle(function (d, i) {
              return ((i + 1) * 2 * Math.PI) / numBars
            })
        )

      renderOverlays(this, update)
    })
  }

  /* Configuration getters/setters */
  chart.margin = function (_) {
    if (!arguments.length) return margin
    margin = _
    return chart
  }

  chart.barHeight = function (_) {
    if (!arguments.length) return barHeight
    barHeight = _
    return chart
  }

  chart.reverseLayerOrder = function (_) {
    if (!arguments.length) return reverseLayerOrder
    reverseLayerOrder = _
    return chart
  }

  chart.barColors = function (_) {
    if (!arguments.length) return barColors
    barColors = _
    return chart
  }

  chart.capitalizeLabels = function (_) {
    if (!arguments.length) return capitalizeLabels
    capitalizeLabels = _
    return chart
  }

  chart.domain = function (_) {
    if (!arguments.length) return domain
    domain = _
    return chart
  }

  chart.tickValues = function (_) {
    if (!arguments.length) return tickValues
    tickValues = _
    return chart
  }

  chart.colorLabels = function (_) {
    if (!arguments.length) return colorLabels
    colorLabels = _
    return chart
  }

  chart.tickCircleValues = function (_) {
    if (!arguments.length) return tickCircleValues
    tickCircleValues = _
    return chart
  }

  chart.transitionDuration = function (_) {
    if (!arguments.length) return transitionDuration
    transitionDuration = _
    return chart
  }

  return chart
}
